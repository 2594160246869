import Brands from 'src/constants/Brands';
import { Vertical } from '../constants/Verticals';

export enum PageType {
    BLOG = 'Blog',
    HOME_PAGE = 'Homepage',
    CATEGORY_PAGE = 'Category page',
    COMPETITOR_PAGE = 'Competitor page',
    INFO_PAGE = 'Info page',
    PERFORMANCE_PAGE = 'Performance page',
    PLAN_PAGE = 'Plan page',
    CHECKOUT = 'Checkout',
    QUIZ = 'Quiz',
    ERROR = 'Error',
    REFERRAL_PAGE = 'Referral page',
    DOCTOR_PAGE = 'Doctor page',
    APP_DOWNLOAD_PAGE = 'App download page',
    PRIVACY_POLICY = 'Privacy policy',
    TERMS = 'Terms',
    THANK_YOU = 'Thank you',
}

export enum DestinationCategory {
    PREQUIZ = 'Pre-quiz',
    QUIZ = 'Quiz',
    USER_PORTAL = 'User portal',
    INFO_PAGE = 'Info page',
    CATEGORY_PAGE = 'Category page',
    BLOG = 'Blog',
}

export enum ButtonAction {
    DIRECT = 'Direct',
    ACCORDION = 'Accordion',
    MODAL = 'Modal',
    SEND_MESSAGE = 'Send message',
    ADD_TO_CART = 'Add to cart',
}

export enum Location {
    STICKY_NAV_BAR = 'StickyNavBar',
}

export type EventProps = {
    brand?: Brands;
    msh?: string;
    user_id?: string;
    anonymous_id?: string | null;
    email?: string;
    vertical?: Vertical;
    location?: string; // where it is located within a page
    label?: string; // button text
    button_action?: string; // what action is button performing
    variation?: string;
    plan?: string;

    destination_category?: DestinationCategory; // where are they going
    destination_url?: string; // url where the button/link links
    destination_vertical?: string;
    destination_brand?: Brands;

    path?: string; // relative path with no params
    link?: string;

    page_type?: PageType;
    pageType?: string;

    title?: string; // nav
    tab?: string; // tabs
    plan_id?: string;
    addon_ids?: Array<string>;
    quiz_token?: string;
    value?: string;
    payment_method?: string;
    version?: string;
    experimentId?: string;
    variationId?: string;

    // Errors
    error_type?: string;
    error_message?: string;

    // Referrer
    referrer?: string;
    anonymousId?: string;
};
