'use client';

import styles from './footer.module.scss';
import MoshyLogo from '../../MoshyLogo/MoshyLogo';
import MoshyLogoLink from '../../MoshyLogoLink/MoshyLogoLink';
import SocialMediaList from '../../SocialMediaList/SocialMediaList';
import FooterNavigation from './FooterNavigation';
import Copyright from '../../Copyright/Copyright';
import { Waypoint } from 'react-waypoint';
import { LegitScript } from '@get-mosh/msh-shared';
import Image from 'next/image';
import Routes from 'src/constants/Routes';

const Footer = () => {
    const handleFooterEntering = (direction?: string) => {
        document.body.setAttribute(
            'data-footer-entered',
            direction === 'in' ? 'true' : 'false',
        );
    };
    return (
        <footer className={styles['footer']}>
            <Waypoint
                onEnter={() => handleFooterEntering('in')}
                onLeave={() => handleFooterEntering('out')}
            >
                <div>
                    <div className={styles['footer--wrapper']}>
                        <div className={styles['footer--wrapper-logo']}>
                            <MoshyLogo variant={'white'} />
                            <MoshyLogoLink />
                        </div>
                        <div className={styles['footer--wrapper-navigation']}>
                            <FooterNavigation />
                        </div>
                    </div>
                    <div className={styles['copyright--wrapper']}>
                        <SocialMediaList />
                        <Copyright />
                    </div>
                    <div className="flex items-center gap-4">
                        <LegitScript id="18705271" />
                        <a href={Routes.QIP} target="_blank">
                            <Image
                                src="/images/logo/qip.png"
                                width={125}
                                height={60}
                                alt="QIP"
                                className="rounded-lg bg-white object-contain p-0.5"
                            />
                        </a>
                    </div>
                </div>
            </Waypoint>
        </footer>
    );
};

export default Footer;
