import { env } from 'src/env.mjs';

const basePath = env.NEXT_PUBLIC_API_PATH;

export default {
    DOCTOR_PLANS: (quizToken: string) =>
        `${basePath}/api/v2/doctor_plans/${quizToken}`,
    USER: (uuid: string) => `${basePath}/api/v2/users/${uuid}`,
    PLAN_CONFIGURATION: `${basePath}/api/v2/plans/configuration`,
    PAYMENT: (
        paymentType:
            | 'card_payment'
            | 'initialize_paypal'
            | 'initialize_afterpay'
            | 'initialise_payment_intent'
            | 'finalise_payment_intent',
    ) => `${basePath}/api/v2/payments/${paymentType}`,
};
