import { usePathname } from 'next/navigation';
import { createContext, useContext, useEffect, useRef } from 'react';

type AppContextType = {
    previousRoute: string | null | undefined;
};

const AppContext = createContext<AppContextType>({} as AppContextType);

export function useAppContext() {
    return useContext(AppContext);
}

type AppProviderProps = {
    children: React.ReactNode;
};

function usePrevious<T>(value: T) {
    const ref = useRef<T | null>(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const usePreviousRoute = () => {
    const pathname = usePathname();
    const previousRoute = usePrevious(pathname);

    return previousRoute;
};

export function AppProvider({ children }: AppProviderProps) {
    const previousRoute = usePreviousRoute();

    return (
        <AppContext.Provider
            value={{
                previousRoute,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}
