'use client';

import { DestinationCategory, PageType } from 'src/analytics/EventProps';
import Routes from 'src/constants/Routes';
import { Vertical } from 'src/constants/Verticals';
import { env } from 'src/env.mjs';
import Headroom from 'headroom.js';
import styles from './header.module.scss';
import MoshyLogo from '../../MoshyLogo/MoshyLogo';
import SideNavigation from '../SideNavigation/SideNavigation';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { trackClick, trackNavigationItemClicked } from 'src/analytics/Events';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Button, TopBar } from '@get-mosh/msh-shared';
import EventNames from 'src/analytics/EventNames';
import Link from 'next/link';
import {
    faArrowRightLong,
    faBookOpen,
    faSprayCanSparkles,
    faUserDoctor,
    faWeightScale,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import commonData from 'src/data/common.json';
import useMoshUrl from 'src/hooks/useMoshUrl';

export type HeaderProps = {
    pageType?: PageType;
    variant?:
        | 'logo-only'
        | 'logo-left'
        | 'default'
        | 'Logo'
        | 'Logo and CTA'
        | 'Logo centered';
    vertical?: Vertical;
    sticky?: boolean;
    mobileCta?: boolean;
    webCta?: boolean;
    ctaText?: string | null;
    ctaLink?: string;
    logoLinkHome?: boolean | string;
    darkMode?: boolean;
    isPrismic?: boolean;
};

const logoVariants = [
    'logo-only',
    'logo-left',
    'Logo',
    'Logo centered',
    'Logo and CTA',
];

const links = [
    {
        label: 'Weight loss',
        icon: faWeightScale,
        href: Routes.WEIGHT_LOSS_LANDING_PAGE,
        destination_category: DestinationCategory.CATEGORY_PAGE,
    },
    {
        label: 'Skincare',
        icon: faSprayCanSparkles,
        href: Routes.SKIN_LANDING_PAGE,
        destination_category: DestinationCategory.CATEGORY_PAGE,
    },
    {
        label: 'Knowledge hub',
        icon: faBookOpen,
        href: Routes.BLOG,
        destination_category: DestinationCategory.BLOG,
    },
    {
        label: 'About us',
        icon: faUserDoctor,
        href: Routes.ABOUT_US,
        destination_category: DestinationCategory.INFO_PAGE,
    },
    {
        label: 'FAQ',
        icon: faQuestionCircle,
        href: Routes.FAQ,
        destination_category: DestinationCategory.INFO_PAGE,
    },
];

const Header = ({
    pageType,
    variant = 'default',
    vertical,
    sticky = false,
    mobileCta = false,
    webCta = false,
    ctaText,
    ctaLink,
    logoLinkHome = true,
    darkMode = false,
    isPrismic = false,
}: HeaderProps) => {
    const [topBarHeight, setTopBarHeight] = useState<number | undefined>(0);
    const navigationRef = useRef<HTMLDivElement | null>(null);
    const pathname = usePathname();
    const moshUrl = useMoshUrl(
        'Moshy',
        'website-banner',
        'Moshy_Website_Banner-Mosh',
        'mosh-banner',
        'check-out-mosh-for-him',
    );

    useEffect(() => {
        const containerEl = document.querySelector(
            '[data-header="navigation"]',
        );
        const setContainerOffsetValue = () => {
            if (containerEl) {
                document
                    .querySelector('html')
                    ?.style.setProperty(
                        '--header-navigation-offset',
                        getComputedStyle(containerEl).marginLeft,
                    );
            }
        };
        setContainerOffsetValue();
        window.addEventListener('resize', () => {
            setContainerOffsetValue();
        });

        const navigationHeight = navigationRef.current?.clientHeight;
        document
            .querySelector('html')
            ?.style.setProperty('--header-height', navigationHeight + 'px');

        const header = document.getElementById('headroom');

        if (!header || !topBarHeight || !navigationHeight) {
            return;
        }

        const headroom = new Headroom(header, {
            offset: topBarHeight + navigationHeight,
        });

        headroom.init();
    }, [topBarHeight]);

    const onLinkClick = (label: string, url: string) => {
        trackClick(EventNames.TOP_BANNER_CLICKED, {
            label,
            destination_url: url,
        });
    };

    const topBarItems = [
        pathname === Routes.HOME
            ? {
                  content: (
                      <a
                          className="flex flex-row items-center gap-2 font-demi"
                          href={moshUrl}
                          onClick={() =>
                              onLinkClick('Check out Mosh for him', moshUrl)
                          }
                      >
                          Check out Mosh for him 👨🏼
                          <FontAwesomeIcon icon={faArrowRightLong} />
                      </a>
                  ) as ReactNode,
                  screen: 'all' as const,
              }
            : {
                  content: (
                      <b>
                          Free shipping | Certified Aussie Practitioners | 100%
                          online
                      </b>
                  ) as ReactNode,

                  screen: 'all' as const,
              },
    ];

    return (
        <header
            id="mainHeader"
            className={classNames(styles['header'], {
                '!sticky top-0 z-50': sticky,
                'bg-green-dark text-white': darkMode,
                'bg-white': !darkMode,
            })}
            data-header="container"
            data-variant={variant}
        >
            {variant === 'logo-only' || isPrismic ? null : (
                <TopBar
                    variant={pathname === Routes.HOME ? 'secondary' : 'primary'}
                    items={topBarItems}
                    onResize={(height) => setTopBarHeight(height)}
                />
            )}
            <div className={styles['wrapper']}>
                <section
                    id="headroom"
                    className={classNames(
                        'left-0 right-0 top-0 z-10 transition-transform duration-200',
                        {
                            [styles['header__section']]: !sticky,
                            'bg-white': !darkMode,
                        },
                    )}
                    ref={navigationRef}
                >
                    <div className="container flex flex-row items-center gap-16">
                        <nav
                            className={classNames(
                                'flex flex-1 items-center gap-16 py-4 ',
                                {
                                    'justify-center':
                                        variant === 'logo-only' ||
                                        variant === 'Logo centered',
                                    'justify-start':
                                        variant === 'logo-left' ||
                                        variant === 'Logo',
                                    'justify-between':
                                        variant === 'Logo and CTA' ||
                                        webCta ||
                                        mobileCta,
                                    'lg:py-0': !logoVariants.includes(variant),
                                },
                            )}
                        >
                            {logoVariants.includes(variant) ? (
                                <>
                                    <MoshyLogo
                                        link={
                                            typeof logoLinkHome === 'string'
                                                ? logoLinkHome
                                                : logoLinkHome
                                                  ? Routes.HOME
                                                  : undefined
                                        }
                                        label="Home"
                                        variant={darkMode ? 'light' : 'dark'}
                                    />
                                    {variant == 'Logo and CTA' ? (
                                        <Button
                                            as="a"
                                            href={ctaLink || Routes.QUIZZES}
                                            text={
                                                ctaText ||
                                                commonData.getStartedNow
                                            }
                                            className={classNames('!w-auto', {
                                                'hidden md:flex':
                                                    mobileCta === false,
                                                'md:hidden': webCta == false,
                                            })}
                                            onClick={() => {
                                                trackClick(
                                                    EventNames.BUTTON_CLICKED,
                                                    {
                                                        link: ctaLink || '#',
                                                        label: ctaText || '',
                                                        location: 'header',
                                                        variation: variant,
                                                    },
                                                );
                                            }}
                                        />
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <MoshyLogo
                                        link={Routes.HOME}
                                        variant={darkMode ? 'white' : 'dark'}
                                        label="Home"
                                    />
                                    <div className={styles['header__links']}>
                                        {links.map(
                                            ({
                                                label,
                                                href,
                                                destination_category,
                                            }) => (
                                                <Link
                                                    key={label}
                                                    href={href}
                                                    onClick={() => {
                                                        trackNavigationItemClicked(
                                                            {
                                                                destination_category,
                                                                destination_url:
                                                                    env.NEXT_PUBLIC_BASE_PATH +
                                                                    href,
                                                                label,
                                                                page_type:
                                                                    pageType,
                                                                vertical,
                                                            },
                                                        );
                                                    }}
                                                    className={classNames({
                                                        [styles[
                                                            'header__link-active'
                                                        ]]: href === pathname,
                                                    })}
                                                >
                                                    {label}
                                                </Link>
                                            ),
                                        )}
                                    </div>
                                </>
                            )}
                        </nav>
                        {variant === 'default' ? (
                            <SideNavigation
                                vertical={vertical}
                                pageType={pageType}
                                links={links}
                            />
                        ) : null}
                    </div>
                </section>
            </div>
        </header>
    );
};

export default Header;
