import Page from '../Page/Page';
import Header from '../Theme/Header/Header';

type LayoutBasicProps = {
    className?: string;
    children: React.ReactNode;
    link?: string;
};

const LayoutBasic = ({ className, children, link }: LayoutBasicProps) => {
    return (
        <>
            <Header variant="logo-only" logoLinkHome={link} />
            <Page className={className}>{children}</Page>
        </>
    );
};

export default LayoutBasic;
